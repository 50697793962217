import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  CFormLabel,
  CForm,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCollapse,
  CButton,
  CFormInput,
  CFormCheck,
  CCardTitle,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react-pro'
import { Col, Row } from 'react-bootstrap'
import PaymentModal from '../ActivateGame/PaymentModal'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'
import RequestUpgradeModal from './RequestUpgradeModal'

const SelectPlan = () => {
  const [visibleA, setVisibleA] = useState(true)
  const [visibleB, setVisibleB] = useState(false)
  const [dNoneClass, setDNoneClass] = useState('d-none')
  const [visible, setVisible] = useState(false)
  const [disabledBtn, setdisabledBtn] = useState(true)
  const [activateGameText, setActivateGameText] = useState('')
  const [activationInfo, setActivationInfo] = useState({})
  const [amount, setAmount] = useState(99)

  const [invoiceLink, setInvoiceLink] = useState(true)

  // const accountInfo = JSON.parse(localStorage.getItem('admin'))
  useEffect(() => {
    CommonService.getGameActivateInfo(1)
      .then((res) => {
        if (res.status === 200) {
          setActivationInfo(res.getGameActivationInfo)
          if (res.getGameActivationInfo) {
            // console.log('activationInfo', activationInfo)
            const invoiceURL = JSON.parse(res.getGameActivationInfo.transaction_information)
            setInvoiceLink(invoiceURL.receipt_url)
          }

          if (
            res.game_info?.game_name &&
            res.game_info?.game_name &&
            res.game_info?.timezone &&
            res?.round_info
          ) {
            setdisabledBtn(false)
            setActivateGameText('Activate Game')
          }
        }
      })
      .catch((e) => {
        console.log('E=> ', e)
        // ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }, [activateGameText])
  useEffect(() => {
    // Ensure the Stripe script is loaded before using the Stripe pricing table
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/v3/pricing-table.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const handleInfoClick = () => {
    setShowUpgradeModal(true)
  }
  return (
    <>
      {' '}
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Custom Plan</strong>
        </CCardHeader>
        <CCardBody>
          {!activationInfo && (
            <stripe-pricing-table
              pricing-table-id="prctbl_1Pi993ItsmZf8AkXqQvSY2yd"
              publishable-key="pk_test_lHEhRjCpRzMBWBxZ6R9i2r5M"
              success-url="http://localhost:3008/plan-success?session_id={CHECKOUT_SESSION_ID}"
            ></stripe-pricing-table>
          )}
          {activationInfo && activationInfo.is_paid === 1 && (
            <CButton color={'success'} shape="rounded-pill" onClick={handleInfoClick}>
              Request Upgrade
            </CButton>
          )}
        </CCardBody>
      </CCard>
      {activationInfo && activationInfo.is_paid === 1 && (
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Transactions</strong>
          </CCardHeader>

          <CCardBody>
            <CTable>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">SN</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Invoice</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Paid On</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableHeaderCell scope="row">1</CTableHeaderCell>
                  <CTableDataCell>
                    {/* <button onClick={handleDownload}>Download Invoice</button> */}
                    <a href={`${invoiceLink}`} target="_blank" rel={'noreferrer'}>
                      Invoice
                    </a>
                  </CTableDataCell>
                  <CTableDataCell>${activationInfo?.fee}</CTableDataCell>
                  <CTableDataCell>
                    {' '}
                    {moment(activationInfo?.created_at).format('D.MM.YYYY h:mm A')}
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CCardBody>
        </CCard>
      )}
      <PaymentModal
        visible={visible}
        setVisible={setVisible}
        activationInfo={activationInfo}
        setActivationInfo={setActivationInfo}
        amount={amount}
        type={1}
        noOfFixture={0}
      />
      <RequestUpgradeModal
        showUpgradeModal={showUpgradeModal}
        setShowUpgradeModal={setShowUpgradeModal}
        id={activationInfo?.id}
      />
    </>
  )
}

export default SelectPlan
