import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilCursor,
  cilSpeedometer,
  cilUser,
  cilGamepad,
  cilUserPlus,
  cilControl,
  cilSettings,
  cilCalendarCheck,
  cilThumbUp,
  cilBolt,
  cibWhatsapp,
  cilListRich,
  cilVideo,
  cilCreditCard,
  cilTransfer,
  cilLink,
  cilInfo,
  cilLifeRing,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react-pro'
const handleClick = (event) => {
  window.Produktly.openChangelog({ id: 240 })
  event.preventDefault()
}
const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: `Get Started`,
    to: '/get-started',
    icon: <CIcon icon={cilInfo} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Quick Links',
    to: '/',
    icon: <CIcon icon={cilLink} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Game Spot',
        to: '/spots',
      },
      {
        component: CNavItem,
        name: 'Game Structure',
        to: '/game-structure',
      },
      {
        component: CNavItem,
        name: 'Manage Scores',
        to: '/manage-scores',
      },
      {
        component: CNavItem,
        name: 'Members List',
        to: '/members',
      },
      {
        component: CNavItem,
        name: 'Players List',
        to: '/players',
      },
      {
        component: CNavItem,
        name: 'Point System',
        to: '/point-system',
      },
      {
        component: CNavItem,
        name: 'Pricing Plan',
        to: '/plan',
      },
      {
        component: CNavItem,
        name: 'Rounds',
        to: '/rounds',
      },
      {
        component: CNavItem,
        name: 'Sponsors',
        to: '/sponsors',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Analytics',
    to: '/',
    icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Members',
        to: '/member-analytics',
      },
      {
        component: CNavItem,
        name: 'Players',
        to: '/player-analytics',
      },
      {
        component: CNavItem,
        name: 'Game',
        to: '/game-analytics',
      },
      {
        component: CNavItem,
        name: 'Game Charts',
        to: '/game-charts',
      },
      {
        component: CNavItem,
        name: 'Game Bars',
        to: '/game-bars',
      },
      {
        component: CNavItem,
        name: 'Branding Log',
        to: '/branding-log',
      },
      {
        component: CNavItem,
        name: 'Sponsor Log',
        to: '/sponsor-logs',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Trades',
    to: '/',
    icon: <CIcon icon={cilTransfer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Trades Used',
        to: '/trades-used',
      },
      {
        component: CNavItem,
        name: 'Trades Log',
        to: '/trades-log',
      },
    ],
  },
  // {
  //   component: CNavGroup,
  //   name: 'Timeline',
  //   to: '/',
  //   icon: <CIcon icon={cilInfinity} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Fantasy',
  //       to: '/timeline-listing/fantasy',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Batting',
  //       to: '/timeline-listing/batting',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Bowling',
  //       to: '/timeline-listing/bowling',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Settings',
  //       to: '/timeline-settings',
  //     },
  //   ],
  // },

  // {
  //   component: CNavGroup,
  //   name: 'Setup',
  //   to: '/',
  //   icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Checklist',
  //       to: '/',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Info Pack',
  //       to: '/',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Info Video',
  //       to: '/',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Features ',
  //       to: '/',
  //     },
  //   ],
  // },
  {
    component: CNavTitle,
    name: 'Game Setup',
  },
  {
    component: CNavGroup,
    name: 'Settings',
    to: '/',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'About Game',
        to: '/club-about-game',
      },
      {
        component: CNavItem,
        name: 'Game Structure',
        to: '/game-structure',
      },
      {
        component: CNavItem,
        name: 'Fantasy Values',
        to: '/fantasy-values',
      },
      {
        component: CNavItem,
        name: 'Rounds',
        to: '/rounds',
      },
      {
        component: CNavItem,
        name: 'Comps',
        to: '/comps',
      },
      {
        component: CNavItem,
        name: 'Teams',
        to: '/teams',
      },
      {
        component: CNavItem,
        name: 'Point System',
        to: '/point-system',
      },
      {
        component: CNavItem,
        name: 'Bonus Points',
        to: '/bonus-points',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Controls',
    to: '/',
    icon: <CIcon icon={cilControl} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Game Spots',
        to: '/spots',
      },
      {
        component: CNavItem,
        name: 'Powers',
        to: '/power-control',
      },
      {
        component: CNavItem,
        name: 'Branding',
        to: '/branding',
      },
      {
        component: CNavItem,
        name: 'Sponsors',
        to: '/sponsors',
      },
      {
        component: CNavItem,
        name: 'Notifications',
        to: '/game-notifications',
      },
      // {
      //   component: CNavItem,
      //   name: 'Prizes',
      //   to: '/prizes',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Notifications',
      //   to: '/game-notifications',
      // },
      {
        component: CNavItem,
        name: 'Articles',
        to: '/articles',
      },
      {
        component: CNavItem,
        name: 'Team Alerts',
        to: '/team-alerts',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Players',
    to: '/',
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Players List',
        to: '/players',
      },
      {
        component: CNavItem,
        name: 'Players Team',
        to: '/player-teams',
      },
      {
        component: CNavItem,
        name: 'Player Availability ',
        to: '/availabilities',
      },
      {
        component: CNavItem,
        name: 'Player Import ',
        to: '/player-import',
      },
      {
        component: CNavItem,
        name: 'Player Profile ',
        to: '/players-profile',
      },
      {
        component: CNavItem,
        name: 'Player Stats ',
        to: '/players-stats',
      },
      {
        component: CNavItem,
        name: 'Player Values',
        to: '/players-values',
      },
      {
        component: CNavItem,
        name: 'Player Rounds',
        to: '/players-rounds',
      },
      {
        component: CNavItem,
        name: 'Inactive Players',
        to: '/inactive-players',
      },
      {
        component: CNavItem,
        name: 'Player Charts',
        to: '/player-charts',
      },
      {
        component: CNavItem,
        name: 'Player Sponsors',
        to: '/player-sponsors',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Fixtures',
    to: '/',
    icon: <CIcon icon={cilGamepad} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Create Fixtures',
        to: '/create-fixtures',
      },
      {
        component: CNavItem,
        name: 'Manage Scores',
        to: '/manage-scores',
      },
      {
        component: CNavItem,
        name: 'Completed Fixtures',
        to: '/completed-fixtures',
      },
      {
        component: CNavItem,
        name: 'Fixture Import',
        to: '/fixture-import',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Members',
    to: '/',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Members List',
        to: '/members',
      },
      {
        component: CNavItem,
        name: 'Manage Members',
        to: '/manage-members',
      },
      {
        component: CNavItem,
        name: 'Verify Members',
        to: '/verify-as-player',
      },
      {
        component: CNavItem,
        name: 'Fantasy Leaderboard',
        to: '/fantasy-leaderboard',
      },
      {
        component: CNavItem,
        name: 'Game Codes',
        to: '/game-codes',
      },
      // {
      //   component: CNavGroup,
      //   name: 'Verifications',
      //   to: '/',
      //   items: [
      //     {
      //       component: CNavItem,
      //       name: 'Paid Members',
      //       to: '/paid-members',
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   component: CNavGroup,
  //   name: 'Analytics',
  //   to: '/',
  //   icon: <CIcon icon={cilElevator} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'danger-gradient',
  //     text: 'PRO',
  //   },
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Members',
  //       to: '/',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Overall',
  //       to: '/',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Players',
  //       to: '/',
  //     },
  //   ],
  // },
  {
    component: CNavTitle,
    name: 'Pro Add-Ons',
  },

  {
    component: CNavGroup,
    name: 'Bonus Cards',
    to: '/',
    icon: <CIcon icon={cilBolt} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Activate',
        to: '/activate-bonus-card',
      },
      {
        component: CNavItem,
        name: 'Manage Bonus Cards',
        to: '/manage-bonus-cards',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Bracket Battle',
    to: '/',
    icon: <CIcon icon={cilBolt} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Activate',
        to: '/activate-bracket-battle',
      },
      {
        component: CNavItem,
        name: 'Bracket Settings ',
        to: '/bracket-battle',
      },
      {
        component: CNavItem,
        name: 'Bracket Rounds',
        to: '/bracket-round',
      },
      {
        component: CNavItem,
        name: 'Round Points',
        to: '/bracket-battle/points',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Head-2-Head',
    to: '/',
    icon: <CIcon icon={cilBolt} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Activate',
        to: '/activate-head-2-head',
      },
      {
        component: CNavItem,
        name: 'H2H Settings',
        to: '/h2h-settings',
      },
      {
        component: CNavItem,
        name: 'H2H Manage',
        to: '/h2h-manage',
      },
      {
        component: CNavItem,
        name: 'H2H Playoffs',
        to: '/h2h-play-off',
      },
      {
        component: CNavItem,
        name: 'H2H Leaderboard',
        to: '/h2h-leaderboard',
      },
      // {
      //   component: CNavItem,
      //   name: 'H2H Actions',
      //   to: '/h2h-actions',
      // },
      // {
      //   component: CNavItem,
      //   name: 'H2H Rounds ',
      //   to: '/h2h-rounds',
      // },
    ],
  },

  // {
  //   component: CNavGroup,
  //   name: 'Feedback Fantasy',
  //   to: '/',
  //   icon: <CIcon icon={cilBolt} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Activate',
  //       to: '/',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Categories',
  //       to: '/feedback-fantasy/category',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Ratings',
  //       to: '/feedback-fantasy/point-system',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Managers',
  //       to: '/feedback-fantasy/managers',
  //     },
  //     {
  //       component: CNavGroup,
  //       name: 'Manager Access',
  //       to: '/',
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: 'By Team',
  //           to: '/feedback-fantasy/manage-access-by-team',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'By Fixture',
  //           to: '/feedback-fantasy/manage-access-by-fixture',
  //         },
  //       ],
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Display Setting',
  //       to: '/feedback-fantasy/display-setting',
  //     },
  //   ],
  // },

  {
    component: CNavGroup,
    name: 'Timeline',
    to: '/',
    icon: <CIcon icon={cilBolt} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Settings',
        to: '/timeline-settings',
      },
      {
        component: CNavItem,
        name: 'Fantasy Timeline',
        to: '/timeline-listing/fantasy',
      },
      {
        component: CNavItem,
        name: 'Point Timeline',
        to: '/timeline-listing/pt',
      },
      {
        component: CNavItem,
        name: 'Rebounds Timeline',
        to: '/timeline-listing/reb',
      },
      {
        component: CNavItem,
        name: 'Assists Timeline',
        to: '/timeline-listing/ast',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Team of the Round',
    to: '/',
    icon: <CIcon icon={cilBolt} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Activate TOTR',
        to: '/activate-team-of-the-round',
      },
      {
        component: CNavItem,
        name: 'Manage TOTR',
        to: '/manage-team-of-the-round',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Votes',
    to: '/fixture-votes',
    icon: <CIcon icon={cilBolt} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Activate Votes',
        to: '/activate-fixture-voting',
      },
      {
        component: CNavItem,
        name: 'Fixture Votes',
        to: '/fixture-voting',
      },
      {
        component: CNavItem,
        name: 'Team Votes',
        to: '/team-fixture-voting',
      },
      {
        component: CNavItem,
        name: 'Total Votes',
        to: '/total-voting',
      },
    ],
  },
  {
    component: CNavTitle,
    name: 'Billings',
  },
  {
    component: CNavGroup,
    name: 'Pricing',
    to: '/',
    icon: <CIcon icon={cilCreditCard} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Plan',
        to: '/plan',
      },
      {
        component: CNavItem,
        name: 'Game Pass',
        to: '/game-pass',
      },
      {
        component: CNavItem,
        name: 'Scorer+',
        to: '/scorer-plus',
      },
    ],
  },
  // {
  //   component: CNavItem,
  //   name: 'Invoices',
  //   to: '/',
  //   icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  // },
  {
    component: CNavTitle,
    name: 'Links',
  },
  {
    component: CNavItem,
    name: `What's New`,
    to: '#',
    icon: <CIcon icon={cilListRich} customClassName="nav-icon" />,
    onClick: handleClick,
  },
  {
    component: CNavItem,
    name: 'Admin Community',
    to: 'https://chat.whatsapp.com/Hlp4McKF83vJ9mlqyXDmvb',
    icon: <CIcon icon={cibWhatsapp} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Admin Help Tours',
    to: 'https://share.layerpath.com/playlist/clyd0i2b5000cjo0c78apwfkv',
    icon: <CIcon icon={cilVideo} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Help Center',
    to: 'https://myclubtap.tawk.help/',
    icon: <CIcon icon={cilLifeRing} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Book Intro Session',
    to: 'https://calendly.com/vickyatmyclubtap/myclubtapintroforgameadminsin30mins?back=1&month=2023-08',
    icon: <CIcon icon={cilCalendarCheck} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Community',
  //   to: '/',
  //   icon: <CIcon icon={cilGroup} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Roadmap/Features',
  //   to: '/',
  //   icon: <CIcon icon={cilMap} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Share Feedback',
    to: 'https://testimonials.myclubtap.com/',
    icon: <CIcon icon={cilThumbUp} customClassName="nav-icon" />,
  },

  // {
  //   component: CNavGroup,
  //   name: 'Forms',
  //   icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Form Control',
  //       to: '/forms/form-control',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Select',
  //       to: '/forms/select',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Multi Select',
  //       to: '/forms/multi-select',
  //       badge: {
  //         color: 'danger-gradient',
  //         text: 'PRO',
  //       },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Checks & Radios',
  //       to: '/forms/checks-radios',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Range',
  //       to: '/forms/range',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Input Group',
  //       to: '/forms/input-group',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Floating Labels',
  //       to: '/forms/floating-labels',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Date Picker',
  //       to: '/forms/date-picker',
  //       badge: {
  //         color: 'danger-gradient',
  //         text: 'PRO',
  //       },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Date Range Picker',
  //       to: '/forms/date-range-picker',
  //       badge: {
  //         color: 'danger-gradient',
  //         text: 'PRO',
  //       },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Time Picker',
  //       to: '/forms/time-picker',
  //       badge: {
  //         color: 'danger-gradient',
  //         text: 'PRO',
  //       },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Layout',
  //       to: '/forms/layout',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Validation',
  //       to: '/forms/validation',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Icons',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Free',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success-gradient',
  //         text: 'FREE',
  //       },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Flags',
  //       to: '/icons/flags',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Brands',
  //       to: '/icons/brands',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Notifications',
  //   icon: <CIcon icon={cilBell} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Modal',
  //       to: '/notifications/modals',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Toasts',
  //       to: '/notifications/toasts',
  //     },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info-gradient',
  //     text: 'NEW',
  //   },
  // },
  // {
  //   component: CNavItem,
  //   name: 'Smart Table',
  //   icon: <CIcon icon={cilGrid} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'danger-gradient',
  //     text: 'PRO',
  //   },
  //   to: '/smart-table',
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Plugins',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Calendar',
  //   icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'danger-gradient',
  //     text: 'PRO',
  //   },
  //   to: '/plugins/calendar',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Charts',
  //   icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  //   to: '/plugins/charts',
  // },
  // {
  //   component: CNavItem,
  //   name: 'Google Maps',
  //   icon: <CIcon icon={cilMap} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'danger-gradient',
  //     text: 'PRO',
  //   },
  //   to: '/plugins/google-maps',
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Extras',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Pages',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Apps',
  //   icon: <CIcon icon={cilLayers} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavGroup,
  //       name: 'Invoicing',
  //       icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
  //       to: '/apps/invoicing',
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: 'Invoice',
  //           badge: {
  //             color: 'danger-gradient',
  //             text: 'PRO',
  //           },
  //           to: '/apps/invoicing/invoice',
  //         },
  //       ],
  //     },
  //     {
  //       component: CNavGroup,
  //       name: 'Email',
  //       to: '/apps/email',
  //       icon: <CIcon icon={cilEnvelopeOpen} customClassName="nav-icon" />,
  //       items: [
  //         {
  //           component: CNavItem,
  //           name: 'Inbox',
  //           badge: {
  //             color: 'danger-gradient',
  //             text: 'PRO',
  //           },
  //           to: '/apps/email/inbox',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Message',
  //           badge: {
  //             color: 'danger-gradient',
  //             text: 'PRO',
  //           },
  //           to: '/apps/email/message',
  //         },
  //         {
  //           component: CNavItem,
  //           name: 'Compose',
  //           badge: {
  //             color: 'danger-gradient',
  //             text: 'PRO',
  //           },
  //           to: '/apps/email/compose',
  //         },
  //       ],
  //     },
  //   ],
  // },
]

export default _nav
