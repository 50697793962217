import React, { useEffect } from 'react'
import {
  CCol,
  CForm,
  CFormSwitch,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormCheck,
  CFormInput,
  CLoadingButton,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import ToastComponent from 'src/components/common/TaostComponent'
import CommonService from 'src/service/CommonService'
import HeaderTheme from './HeaderTheme'
import GameMenuTheme from './GameMenuTheme'

const ThemeSetting = () => {
  const [data, setData] = useState({})
  const [showPosBased, setShowPosBased] = useState(0)
  const [showHeaderValue, setShowHeaderValue] = useState(1)
  const [showGameMenuValue, setShowGameMenuValue] = useState(0)
  const handleChangeStatus = (event) => {
    const value = event.target.id === 'success-outlined' ? 0 : 1
    setShowPosBased(value)
  }
  useEffect(() => {
    var adminDetails = JSON.parse(localStorage.getItem('admin'))
    CommonService.getThemeSettingData(adminDetails?.id).then((res) => {
      if (res.status === 200) {
        setData(res.data)
        setShowPosBased(res.data?.is_default)
        setShowHeaderValue(res.data?.is_image)
        setShowGameMenuValue(res.data?.is_default_sidebar)
      }
    })
  }, [])

  const formik = useFormik({
    initialValues: {
      left_bg_color: data?.left_bg_color || '#0B1449',
      right_bg_color: data?.right_bg_color || '#CA1744',
    },
    enableReinitialize: true,
    onSubmit: (data) => {
      var adminDetails = JSON.parse(localStorage.getItem('admin'))
      data.is_default = showPosBased
      data.clubId = adminDetails?.id
      CommonService.updateThemeSetting(data).then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
        }
      })
    },
  })
  const handleHeaderChangeStatus = (event) => {
    const value = event.target.id === 'default-background' ? 1 : 0
    setShowHeaderValue(value)
  }
  const handleSideBarMenuColorChangeStatus = (event) => {
    const value = event.target.id === 'default-sidebar-color' ? 0 : 1
    setShowGameMenuValue(value)
  }
  return (
    <>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Hub Background</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <CRow>
              <CCol md={12}>
                <CForm className="row g-3" onSubmit={formik.handleSubmit}>
                  <div>
                    <CFormCheck
                      button={{ color: 'success', variant: 'outline' }}
                      type="radio"
                      name="options-outlined"
                      id="success-outlined"
                      autoComplete="off"
                      label="Default Color"
                      checked={showPosBased === 0}
                      onChange={handleChangeStatus}
                    />
                    &nbsp;
                    <CFormCheck
                      button={{ color: 'danger', variant: 'outline' }}
                      type="radio"
                      name="options-outlined"
                      id="danger-outlined"
                      autoComplete="off"
                      label="Custom Color"
                      checked={showPosBased === 1}
                      onChange={handleChangeStatus}
                    />
                    {showPosBased === 1 && (
                      <>
                        <CRow>
                          <CCol md={6} xs={6}>
                            <label htmlFor="leftColorInput" className="fw-bold">
                              Left Gradient
                            </label>
                            <CFormInput
                              type="color"
                              id="leftColorInput"
                              name="left_bg_color"
                              value={formik.values.left_bg_color}
                              onChange={formik.handleChange}
                              title="Choose your color"
                            />
                          </CCol>
                          <CCol md={6} xs={6}>
                            <label htmlFor="leftColorInput" className="fw-bold">
                              Hex Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="left_bg_color"
                              value={formik.values.left_bg_color}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md={6} xs={6} className="pt-3">
                            <label htmlFor="rightColorInput" className="fw-bold">
                              Right Gradient
                            </label>
                            <CFormInput
                              type="color"
                              id="rightColorInput"
                              name="right_bg_color"
                              value={formik.values.right_bg_color}
                              onChange={formik.handleChange}
                              title="Choose your color"
                            />
                          </CCol>
                          <CCol md={6} xs={6} className="pt-3">
                            <label htmlFor="leftColorInput" className="fw-bold">
                              Hex Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="right_bg_color"
                              value={formik.values.right_bg_color}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </CCol>
                        </CRow>
                      </>
                    )}
                  </div>
                  <CCol md={6}>
                    <CLoadingButton type="submit" color="success" variant="outline" id="submit">
                      Submit
                    </CLoadingButton>
                  </CCol>
                </CForm>
              </CCol>
            </CRow>
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>

      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Header</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <HeaderTheme
              data={data}
              handleHeaderChangeStatus={handleHeaderChangeStatus}
              showHeaderValue={showHeaderValue}
              setShowHeaderValue={setShowHeaderValue}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>
            <strong>Game Menu</strong>
          </CAccordionHeader>
          <CAccordionBody>
            <GameMenuTheme
              data={data}
              handleSideBarMenuColorChangeStatus={handleSideBarMenuColorChangeStatus}
              showGameMenuValue={showGameMenuValue}
              setShowGameMenuValue={setShowGameMenuValue}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </>
  )
}

export default ThemeSetting
