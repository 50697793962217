import React, { useEffect } from 'react'
import {
  CCol,
  CForm,
  CFormSwitch,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormCheck,
  CFormInput,
  CLoadingButton,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'

const HeaderTheme = (props) => {
  const formik = useFormik({
    initialValues: {
      image_url: props.data?.image_url || '../images/bannerbg1.jpg',
      header_bg_color: props.data?.header_bg_color || '#CA1744',
    },
    enableReinitialize: true,
    onSubmit: (data) => {
      var adminDetails = JSON.parse(localStorage.getItem('admin'))
      data.is_image = props.showHeaderValue
      data.clubId = adminDetails?.id
      CommonService.updateHeaderThemeSetting(data).then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
        }
      })
    },
  })

  return (
    <>
      <CRow>
        <CCol md={12}>
          <CForm className="row g-3" onSubmit={formik.handleSubmit}>
            <div>
              <CFormCheck
                button={{ color: 'success', variant: 'outline' }}
                type="radio"
                name="success-outlined"
                id="default-background"
                autoComplete="off"
                value={1}
                label="Default Background"
                checked={props.showHeaderValue == 1}
                onChange={props.handleHeaderChangeStatus}
              />
              &nbsp;
              <CFormCheck
                button={{ color: 'danger', variant: 'outline' }}
                type="radio"
                name="options-outlined"
                value={1}
                id="theme-custom-outlined"
                autoComplete="off"
                label="Custom Color"
                checked={props.showHeaderValue == 0}
                onChange={props.handleHeaderChangeStatus}
              />
              {props.showHeaderValue === 0 && (
                <>
                  <CRow>
                    <CCol md={6} xs={6} className="pt-3">
                      <label htmlFor="rightColorInput" className="fw-bold">
                        Pick Color
                      </label>
                      <CFormInput
                        type="color"
                        id="rightColorInput"
                        name="header_bg_color"
                        value={formik.values.header_bg_color}
                        onChange={formik.handleChange}
                        title="Choose your color"
                      />
                    </CCol>
                    <CCol md={6} xs={6} className="pt-3">
                      <label htmlFor="leftColorInput" className="fw-bold">
                        Hex Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="header_bg_color"
                        value={formik.values.header_bg_color}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </CCol>
                  </CRow>
                </>
              )}
            </div>
            <CCol md={6}>
              <CLoadingButton type="submit" color="success" variant="outline" id="submit">
                Submit
              </CLoadingButton>
            </CCol>
          </CForm>
        </CCol>
      </CRow>
    </>
  )
}

export default HeaderTheme
