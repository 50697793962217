import { agent } from '../utils/agent'
const API_URL = process.env.REACT_APP_API_URL
const getTeamAlert = async (offset, itemsPerPage, activePage, params) => {
  const response = await agent.get(
    `${API_URL}team-alerts/listing?offset=${offset}&limit=${itemsPerPage}&page=${activePage}&${params}`,
  )
  return response.data
}
const saveTeamAlert = async (data) => {
  const response = await agent.post(API_URL + 'team-alerts/saveTeamAlert', data)
  return response.data
}
const editTeamAlert = async (data) => {
  const response = await agent.post(API_URL + 'team-alerts/editTeamAlert', data)
  return response.data
}
const teamAlertDetail = async (id) => {
  const response = await agent.get(`${API_URL}team-alerts/teamAlertDetail/${id}`)
  return response.data
}

const deleteTeamAlert = async (data) => {
  const response = await agent.post(API_URL + 'team-alerts/deleteTeamAlert', data)
  return response.data
}

const sendTeamAlert = async (id, status) => {
  const response = await agent.get(`${API_URL}team-alerts/sendTeamAlert/${id}/${status}`)
  return response.data
}

const TeamAlertService = {
  getTeamAlert,
  saveTeamAlert,
  deleteTeamAlert,
  teamAlertDetail,
  editTeamAlert,
  sendTeamAlert,
}

export default TeamAlertService
