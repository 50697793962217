import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'
import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'

const RequestUpgradeModal = (props) => {
  const validationSchema = Yup.object().shape({
    request_text: Yup.string().required('Request Text field is required.'),
  })
  const formik = useFormik({
    initialValues: {
      request_text: props.userDetail?.timezone,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data) => {
      data.id = props.id
      CommonService.saveCustomPlanRequest(data).then((res) => {
        if (res.status === 200) {
          ToastComponent(res.message, 'success')
          props.setShowUpgradeModal(false)
        }
      })
    },
  })
  return (
    <CModal
      alignment="center"
      size="lg"
      scrollable
      visible={props.showUpgradeModal}
      onClose={() => props.setShowUpgradeModal(false)}
    >
      <CModalHeader>
        <CModalTitle>Request Upgrade</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm className="row g-3" onSubmit={formik.handleSubmit}>
          <CCol md={12}>
            <CFormLabel className="fw-bold" htmlFor="Post Code">
              Request Text *
            </CFormLabel>
            <CFormTextarea
              id="request_text"
              rows="3"
              placeholder="Request Text*"
              className={
                'form-control' +
                (formik.errors.request_text && formik.touched.request_text ? ' is-invalid' : '')
              }
              defaultValue={formik.values.request_text}
              name="request_text"
              onChange={formik.handleChange}
            />
            {formik.errors.request_text && formik.touched.request_text && (
              <CFormFeedback invalid>{formik.errors.request_text}</CFormFeedback>
            )}
          </CCol>
          <CCol md={6}>
            <CLoadingButton type="submit" color="success" variant="outline">
              Submit
            </CLoadingButton>
          </CCol>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton color="primary" onClick={() => props.setShowUpgradeModal(false)}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default RequestUpgradeModal
